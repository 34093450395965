import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { Router } from '@angular/router';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
import { ReportTypesModelAPI, ReportTypesModel, ReportTypesDataModel } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/shared/utilities/global';
import { GenerateReportPdfModel, ListReportModel, GenerateAutomaticWeatherReportPdf, GetAutomaticWeatherReportsListingModel, MannedForecastModel, ParametersMap, CityListModel } from 'src/app/models/manned-forecast/manned-forecast.model';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';
@Component({
  selector: 'app-automatic-weather-report',
  templateUrl: './automatic-weather-report.component.html',
  styleUrls: ['./automatic-weather-report.component.scss']
})
export class AutomaticWeatherReportComponent implements OnInit {
  @Output() onNavChange = new EventEmitter<string>();
  @Input() navItems:string[] = [];
  @Input() activeNav:string;

  private searchInputChange = new Subject<string>();
  private subscription: Subscription = new Subscription();
  language: string;
  automaticWeatherPage: any;
  mannedforecast: any;

  pageSize: number = 10;
  page: number = 1;
  entries: { id: number, label: string, value: number, label_ar: string,}[];
  selectedEntry: { id: number, label: string, value: number };
  searchdata:any;
  allsearchdata:any;
  alllist:any;
  generateReport:any;

  reporttypedata: ReportTypesDataModel[];
  search: string;
  AddReport: boolean = false;
  reportsForm: FormGroup;
  currentdate: any;
  oneweekdate:any;
  permissions: any;
  checked:boolean;
  alldata:any;
  cityid:any[]=[];
  allcities:any;
  reportTitle:any;
  reportTitleAR:any;
  reportdetail: any;
  earlyWarningHistorySection: boolean = false;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private mannedForecastService: MannedForecast,
    private toast: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) {
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticWeatherPage = res;
      });
      this.translate.get("MannedForecast").subscribe((res) => {
        this.mannedforecast = res;
      });
    }));
    this.subscription.add(this.globalService.getPermissions().subscribe((res: any) => {
      this.permissions = JSON.parse(res);
      if (this.permissions.list_ncm_user == false) {
        this.toast.error("Access Denied Contact Your Administrator");
        this.router.navigateByUrl('home/dashboard');
      }
    }));
    const dateNow = new Date().toISOString()
    const fromDate = new Date(dateNow);

    fromDate.setHours(0,0,0,0);
    const toDate = new Date(fromDate.getTime() + 9 * 24 * 60 * 60 * 1000);
    toDate.setHours(0,0,0,0);
    this.currentdate = this.datePipe.transform(fromDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("current_date",this.currentdate);
    this.oneweekdate = this.datePipe.transform(toDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("oneweekdate", this.oneweekdate);
    this.reportsForm = this.fb.group({
      weathersymbol:true,
      temperature: true,
      humidity: false,
      windspeed: false,
      winddirection: false,
      windpressure: false,
      precipitation: false,
      probability: false,
      slipperyroad: false,
    });
    this.entries = [
      { id: 1, label: 'Show: 10', value: 10, label_ar: 'عرض: 10' },
      { id: 2, label: 'Show: 20', value: 20, label_ar: 'عرض: 20' },
      { id: 3, label: 'Show: 30', value: 30, label_ar: 'عرض: 30' },
      { id: 4, label: 'Show: 40', value: 40, label_ar: 'عرض: 40' },
      { id: 5, label: 'Show: 50', value: 50, label_ar: 'عرض: 50' },
    ];
    this.selectedEntry = this.entries[0];
   }

  ngOnInit(): void {
    this.searchInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.search = filterValue;
      this.page = 1
      this.getAllReports();
    });
    this.getReportTypes();
    this.getAllReports();
  }

  getReportTypes(){
    this.spinner.show()
    const reportTypesModel: ReportTypesModelAPI = new ReportTypesModelAPI()
    reportTypesModel.automatic = 1
    this.automaticWeatherReportservice.getReportTypes(reportTypesModel)
    .then((res:{success: boolean, data: ReportTypesDataModel[]}) => {
      this.spinner.hide();
      this.reporttypedata = res.data;
    })
    .catch((error: HttpErrorResponse) => {
      this.spinner.hide();
      this.globalService.handleError(error);
    })
    .finally(() => {
      this.spinner.hide();
    })
  }
  getAllReports(){
    this.spinner.show();
    const listReportModel: ListReportModel = new ListReportModel();
    listReportModel.page = this.page;
    listReportModel.limit = this.pageSize;
    listReportModel.report_type = "automatic";
    if(this.search){
      listReportModel.search = this.search;
    }
    this.mannedForecastService.getListReport(listReportModel).then(
      (res: any) => {
        this.spinner.hide();
        this.searchdata = res;
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  generatePdfReport(id:number){
    this.spinner.show();
    const generateAutomaticWeatherReportPdf: GenerateAutomaticWeatherReportPdf = new GenerateAutomaticWeatherReportPdf();
    generateAutomaticWeatherReportPdf.id = id;
    generateAutomaticWeatherReportPdf.lang = this.language;
    this.mannedForecastService.generateAutomaticPDFReport(generateAutomaticWeatherReportPdf).then(
      (res: any) => {
        if(res.success){
          this.spinner.hide();
          this.generateReport = res.data;
          window.open(this.generateReport);
        }
        else{
          this.spinner.hide();
          this.toast.error('PDF Report Not Available. Please try later.');
        }
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  onChangeEntry(entry: number) {
    this.pageSize = entry;
    this.getAllReports()
  }

  onTypeUser(event:Event){
    const input = event.target as HTMLInputElement;
    this.searchInputChange.next(input.value);
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getAllReports();
  }

  goToPreview(report: any) {
    this.router.navigateByUrl(`home/automatic-weather-report/automatic-weather-report-details?reporttype=${report.id}&reportKey=${report.key}&descriptionEn=${report.descriptionEn}&descriptionAr=${report.descriptionAr}&titleEn=${report.titleEn}&titleAr=${report.titleAr}`);
  }
  getEarlyWarningHistory(){
    this.earlyWarningHistorySection = true;
    this.AddReport = false;
  }
  getReportType(event:any){
    this.AddReport = true;
    this.earlyWarningHistorySection = false;
    this.reportdetail = event;
    this.getAllCities();
  }
  getAllCities(){
    this.spinner.show();
    const cityListModel: CityListModel = new CityListModel();
    cityListModel.Authorization = localStorage.getItem("token");
    cityListModel.report_type_id = this.reportdetail.id;
    this.mannedForecastService.getCityList(cityListModel).then(
      (res: any) => {
        this.spinner.hide();
        this.allcities = res.data;
        this.cityid = this.allcities.map(city => city.id);
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  publishReport(){

    if (this.reportTitle === null || this.reportTitleAR === null) {
      this.toast.error('Please enter Report Title');
      return false;
    }
    this.spinner.show();
    // const dateNow = new Date().toISOString()
    // const fromDate = new Date(dateNow);

    // fromDate.setHours(0,0,0,0);
    // const toDate = new Date(fromDate.getTime() + 14 * 24 * 60 * 60 * 1000);
    // toDate.setHours(0,0,0,0);
    const now = moment();
    const saudiTime = moment.tz(now, 'Asia/Riyadh').startOf('day').set('hour', 0);
    const todayDate = saudiTime.utc().format();
    const fromDate = saudiTime.utc().format();
    const nextDayDate = moment(fromDate).add(9, 'days').utc().format();
    const todaysDate = this.datePipe.transform(todayDate, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    const nextDate = this.datePipe.transform(nextDayDate, "yyyy-MM-ddTHH:mm:ss\'Z\'", 'UTC');
    // const toDate = new Date()
    // toDate.setDate(toDate.getDate() + 14)
    this.spinner.show();
    const mannedForecastModel: MannedForecastModel = new MannedForecastModel();
    mannedForecastModel.from_date = todaysDate;
    mannedForecastModel.to_date = nextDate;
    mannedForecastModel.hours = "24";
    const selectedParameters: string[] = [];
    for (const [key, value] of Object.entries(this.reportsForm.controls)) {
      if (value.value === true) {
        selectedParameters.push(ParametersMap[key]);
      }
    }
    if(selectedParameters.length < 2){
      this.spinner.hide();
      this.toast.error('Please select a Weather Phenomena');
      return
    }
    mannedForecastModel.parameters_24h = selectedParameters.flat();
    mannedForecastModel.parameters_12h = [];
    mannedForecastModel.model = "mix";
    mannedForecastModel.locations = this.cityid;
    mannedForecastModel.is_governate = false;
    let reportid;
    this.reporttypedata.forEach((types) => {
      if(types.key == '10-day-forecast-report'){
        reportid = types.id;
      }
    })
    mannedForecastModel.report_type_id = reportid;
    this.mannedForecastService.createMannedForecastReport(mannedForecastModel).then(
      (res: any) => {
      this.spinner.show();

        if(res.success){
          this.spinner.hide();
          this.alldata = res.data;
          this.mannedForecastService.previewdata = this.alldata;
          this.mannedForecastService.locationslist = this.cityid;
          this.mannedForecastService.start_date = this.currentdate;
          this.mannedForecastService.end_date = this.oneweekdate;
          this.mannedForecastService.report_title = this.reportTitle;
          this.mannedForecastService.report_title_ar = this.reportTitleAR;
          this.goToMannedPreview();
        }
        else{
          this.spinner.hide();
          this.toast.error('Report Preview Not Available. Please try later.');
        }
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  OnSelectAll(event: any) {
    Object.entries(this.reportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol' && key !== 'temperature') {
        control.setValue(event.target.checked);
      }
    });
  }

  goToMannedPreview() {
    this.router.navigate(['home/manned-forecast/manned-forecast-preview']);
  }
}
