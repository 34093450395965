<div *ngIf="isDivVisible" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancel()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{usersPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{usersPage?.DoYouReallyWant}}
    </p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="deleteUser(deleteLocationData);cancel()">
        <i class="fas fa-trash-alt"></i>
        {{usersPage?.Delete}}
      </button>
      <button class="cancel-btn" (click)="cancel()">
        <i class="fas fa-times"></i>
        {{usersPage?.Cancel}}
      </button>
    </div>
  </div>
</div>

<!-- <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{usersPage?.User}}</p>
  </div> -->
<!-- <div class="locationBG justify-content-between">
  <div class="addLocHeading">
    <img class="headerIcon" src="../../../assets/addusers.svg" />
    <h3>{{usersPage?.User}}</h3>
  </div>
  <div class="d-flex align-items-center">
    <button pButton type="button" (click)="openAddUserWindow()" class="bgBtn addNewUserBtn"
      [label]="usersPage?.AddNewUsers"><img src="../../../assets/addIcon.svg" /></button>
  </div>
</div> -->
<p-toolbar class="row" style="margin: inherit;">
  <div class="p-toolbar-group-left col justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <img src="../../../assets/addusers.svg" style="scale: 0.7;">
      <div class="alert-history">{{usersPage?.User}}
      </div>
    </div>
    <div class="d-flex gap-3">
      <button (click)="openInviteUserWindow()" class="user-btn" *ngxPermissionsOnly="['invite_ncm_user']">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="22.356" height="20" viewBox="0 0 22.356 20">
          <path id="Icon_awesome-user-plus" data-name="Icon awesome-user-plus" d="M21.731,7.244h-2.5v-2.5a.627.627,0,0,0-.625-.625h-1.25a.627.627,0,0,0-.625.625v2.5h-2.5a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h2.5v2.5a.627.627,0,0,0,.625.625h1.25a.627.627,0,0,0,.625-.625v-2.5h2.5a.627.627,0,0,0,.625-.625V7.869A.627.627,0,0,0,21.731,7.244ZM8.75,10a5,5,0,1,0-5-5A5,5,0,0,0,8.75,10Zm3.5,1.25H11.6a6.8,6.8,0,0,1-5.7,0H5.25A5.251,5.251,0,0,0,0,16.5v1.625A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V16.5A5.251,5.251,0,0,0,12.25,11.25Z" class="svg"/>
        </svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Icon_fa-solid-envelope-open-text" data-name="Icon fa-solid-envelope-open-text" d="M8.414,3.75H3.75v6.93L.008,7.91a2.5,2.5,0,0,1,1-1.789l.863-.641V3.75A1.875,1.875,0,0,1,3.75,1.875H6.742L8.691.434A2.192,2.192,0,0,1,10,0a2.215,2.215,0,0,1,1.309.43l1.949,1.445H16.25A1.875,1.875,0,0,1,18.125,3.75V5.48l.863.641a2.5,2.5,0,0,1,1,1.789L16.25,10.68V3.75ZM0,17.5V9.457l8.5,6.3a2.531,2.531,0,0,0,1.5.5,2.5,2.5,0,0,0,1.5-.5l8.5-6.3V17.5A2.5,2.5,0,0,1,17.5,20H2.5A2.5,2.5,0,0,1,0,17.5ZM6.875,6.25h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Zm0,2.5h6.25a.625.625,0,0,1,0,1.25H6.875a.625.625,0,0,1,0-1.25Z" class="svg"/>
        </svg>
        {{usersPage?.InviteUser}}
      </button>
      <button (click)="openAddUserWindow()" class="user-btn" *ngxPermissionsOnly="['invite_ncm_user']">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.356" height="20" viewBox="0 0 22.356 20">
          <path id="Icon_awesome-user-plus" data-name="Icon awesome-user-plus" d="M21.731,7.244h-2.5v-2.5a.627.627,0,0,0-.625-.625h-1.25a.627.627,0,0,0-.625.625v2.5h-2.5a.627.627,0,0,0-.625.625v1.25a.627.627,0,0,0,.625.625h2.5v2.5a.627.627,0,0,0,.625.625h1.25a.627.627,0,0,0,.625-.625v-2.5h2.5a.627.627,0,0,0,.625-.625V7.869A.627.627,0,0,0,21.731,7.244ZM8.75,10a5,5,0,1,0-5-5A5,5,0,0,0,8.75,10Zm3.5,1.25H11.6a6.8,6.8,0,0,1-5.7,0H5.25A5.251,5.251,0,0,0,0,16.5v1.625A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V16.5A5.251,5.251,0,0,0,12.25,11.25Z" class="svg"/>
        </svg>
        {{usersPage?.AddNewUsers}}
      </button>
    </div>
  </div>
</p-toolbar>

<div class="AddUserForm" *ngIf="AddUser">
  <div class="row" style="margin: unset;padding: unset;">
    <form [formGroup]="addUsersForm" style="margin: unset;padding: unset;">
      <div class="d-flex flex-wrap gap-3 cpx-4 cpt-4">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{usersPage?.Role}}</mat-label> <!-- sameWidth client-lin-marg-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 role -->
          <mat-select formControlName="role" (selectionChange)="onRoleChange($event)">
            <mat-option value="NCM_IT">{{usersPage?.Ncmit}}</mat-option>
            <mat-option value="NCM_OPERATOR">{{usersPage?.NCMOperator}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
          <mat-label>{{usersPage?.EnterFullName}}</mat-label>
          <input formControlName="fullName"  type="text" matInput>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
          <mat-label>{{usersPage?.AddUserEmail}}</mat-label>
          <input formControlName="email" type="text" matInput>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{usersPage?.SelectTag}}</mat-label>
          <mat-select formControlName="tags" msInfiniteScroll (infiniteScroll)="infiniteUserTagsForDropdown()">
            <mat-option *ngFor="let tag of allTags" [value]="tag.id">{{tag?.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="d-flex flex-column mb-2 col-xl-2 col-lg-4 col-md-6 col-12" style="position: relative;">
          <!-- *ngIf="isEditUser && userRole === 'NCM_IT'" -->
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight w-100" appearance="outline">
            <mat-label>{{usersPage?.EnterPassword}}</mat-label>
            <input formControlName="password" type="password" matInput (input)="checkPasswordValidity()">
          </mat-form-field>
          <span *ngIf="passwordError" class="error-message" style="position: absolute; bottom: -30px;">{{ passwordError }}</span>
        </div>
        <div class="d-flex flex-column mb-2 col-xl-2 col-lg-4 col-md-6 col-12" style="position: relative;">
          <!-- *ngIf="isEditUser && userRole === 'NCM_IT'" -->
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight w-100" appearance="outline">
            <mat-label>{{usersPage?.ConfirmPassword}}</mat-label>
            <input formControlName="confirmpassword" type="password" matInput (input)="checkPasswordValidity()">
          </mat-form-field>
          <span *ngIf="confirmPasswordError" class="error-message" style="position: absolute; bottom: -20px;">{{ confirmPasswordError }}</span>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-3 cpx-4 cpt-4">
        <button class="previewbtn" (click)="openCreateTagModal(createTag)" style="padding: 10px;width: 150px;">{{usersPage?.CreateTags}}</button>
      </div>
      <div class="permissionsDiv d-flex flex-column mt-2 mb-4">
        <h3 class="previewHeadingsColor cpx-4 mb-1 mt-3">{{usersPage?.SetPermissions}}</h3>
        <form [formGroup]="listPrivateForm" class="d-flex flex-wrap w-100">
          <div class="field-checkbox d-flex flex-column w-100">
            <div class="d-flex w-100 tableLIKE">
              <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-2 col-sm-2 col-2"></div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.FullAccess}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.View}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Add}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Edit}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Delete}}</div>
            </div>
            <ng-container *ngFor="let groupIndex of getGroupIndices()">
              <div class="d-flex w-100">
                <ng-container *ngFor="let item of getGroupItems(groupIndex); let i = index">
                  <ng-container *ngIf="i === 0">
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-2 col-sm-2 col-2 cps-4">
                      <label>{{ language == 'en' ? item?.name : item?.name_ar }}</label>
                    </div>
                  </ng-container>
                  <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center">
                    <p-checkbox *ngIf="i === 0; else normalCheckbox"
                                [formControl]="listPrivateForm.get(item.id.toString())"
                                [binary]="true"
                                [inputId]="item.id.toString()"
                                (onChange)="handleFullAccessChange(groupIndex, true)">
                    </p-checkbox>
                    <ng-template #normalCheckbox>
                      <p-checkbox [formControl]="listPrivateForm.get(item.id.toString())"
                                  [binary]="true"
                                  [inputId]="item.id.toString()"
                                  (onChange)="handlePrivateApiGroupClick(groupIndex)">
                      </p-checkbox>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-start align-items-center gap20 cpx-4 cpb-4">
        <button (click)="onAddUserSave()" class="save-btn">
          <i class="far fa-save"></i>
          {{usersPage?.SaveUser}}
        </button>
        <button (click)="closeAddUserWindow()" class="cancel-btn">
          <i class="fas fa-times"></i>
          {{usersPage?.Cancel}}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="AddUserForm" *ngIf="InviteUser">
  <div class="row" style="margin: unset;padding: unset;">
    <form [formGroup]="usersForm" style="margin: unset;padding: unset;">
      <div class="d-flex flex-wrap gap-3 cpx-4 cpt-4">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{usersPage?.Role}}</mat-label> <!-- sameWidth client-lin-marg-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 role -->
          <mat-select formControlName="role" (selectionChange)="onRoleChange($event)">
            <mat-option value="NCM_IT">{{usersPage?.Ncmit}}</mat-option>
            <mat-option value="NCM_OPERATOR">{{usersPage?.NCMOperator}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
          <mat-label>{{usersPage?.EnterFullName}}</mat-label>
          <input formControlName="fullName"  type="text" matInput>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
          <mat-label>{{usersPage?.AddUserEmail}}</mat-label>
          <input formControlName="email"  type="text" matInput>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="sameHeight mb-2 col-xl-2 col-lg-4 col-md-6 col-12">
          <mat-label>{{usersPage?.SelectTag}}</mat-label>
          <mat-select formControlName="tags" msInfiniteScroll (infiniteScroll)="infiniteUserTagsForDropdown()">
            <mat-option *ngFor="let tag of allTags" [value]="tag.id">{{tag?.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="d-flex flex-column mb-2 col-xl-2 col-lg-4 col-md-6 col-12" style="position: relative;">
          <mat-form-field *ngIf="isEditUser && userRole === 'NCM_IT'" [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight w-100" appearance="outline">
            <mat-label>{{usersPage?.EnterPassword}}</mat-label>
            <input formControlName="password" type="password" matInput (input)="checkPasswordValidity()">
          </mat-form-field>
          <span *ngIf="passwordError" class="error-message" style="position: absolute; bottom: -30px;">{{ passwordError }}</span>
        </div>
        <div class="d-flex flex-column mb-2 col-xl-2 col-lg-4 col-md-6 col-12" style="position: relative;">
          <mat-form-field *ngIf="isEditUser && userRole === 'NCM_IT'" [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameHeight w-100" appearance="outline">
            <mat-label>{{usersPage?.ConfirmPassword}}</mat-label>
            <input formControlName="confirmpassword" type="password" matInput (input)="checkPasswordValidity()">
          </mat-form-field>
          <span *ngIf="confirmPasswordError" class="error-message" style="position: absolute; bottom: -20px;">{{ confirmPasswordError }}</span>
        </div>
      </div>
      <div class="d-flex flex-wrap gap-3 cpx-4 cpt-4">
        <button class="previewbtn" (click)="openCreateTagModal(createTag)" style="padding: 10px;width: 150px;">{{usersPage?.CreateTags}}</button>
      </div>
      <div class="permissionsDiv d-flex flex-column mt-2 mb-4">
        <h3 class="previewHeadingsColor cpx-4 mb-1 mt-3">{{usersPage?.SetPermissions}}</h3>
        <form [formGroup]="listPrivateForm" class="d-flex flex-wrap w-100">
          <div class="field-checkbox d-flex flex-column w-100">
            <div class="d-flex w-100 tableLIKE">
              <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-2 col-sm-2 col-2"></div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.FullAccess}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.View}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Add}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Edit}}</div>
              <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center text-center">{{usersPage?.Delete}}</div>
            </div>
            <ng-container *ngFor="let groupIndex of getGroupIndices()">
              <div class="d-flex w-100">
                <ng-container *ngFor="let item of getGroupItems(groupIndex); let i = index">
                  <ng-container *ngIf="i === 0">
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-2 col-sm-2 col-2 cps-4">
                      <label>{{ language == 'en' ? item?.name : item?.name_ar }}</label>
                    </div>
                  </ng-container>
                  <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 d-flex justify-content-center">
                    <p-checkbox *ngIf="i === 0; else normalCheckbox"
                                [formControl]="listPrivateForm.get(item.id.toString())"
                                [binary]="true"
                                [inputId]="item.id.toString()"
                                (onChange)="handleFullAccessChange(groupIndex, true)">
                    </p-checkbox>
                    <ng-template #normalCheckbox>
                      <p-checkbox [formControl]="listPrivateForm.get(item.id.toString())"
                                  [binary]="true"
                                  [inputId]="item.id.toString()"
                                  (onChange)="handlePrivateApiGroupClick(groupIndex)">
                      </p-checkbox>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-start align-items-center gap20 cpx-4 cpb-4">
        <button (click)="onSave()" class="save-btn">
          <i class="far fa-save"></i>
          {{usersPage?.SaveUser}}
        </button>
        <button (click)="closeInviteUserWindow()" class="cancel-btn">
          <i class="fas fa-times"></i>
          {{usersPage?.Cancel}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="AddUserTable" *ngxPermissionsOnly="['list_ncm_user']">
  <div class="d-flex flex-wrap justify-content-between align-items-center" style="padding: 1.2rem 1.188rem;">
    <div class="addLocHeading col-xl-2 col-lg-12 col-12">
      <h3>{{usersPage?.AddedUsers}}</h3>
    </div>
    <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12 client-table-gap">
      <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 cpx-1">
        <!-- <input class="inputclass w-100" type="text" [placeholder]="clientsPage?.SearchByNameOrEmail" (keyup)="onTypeEmail($event)"> -->
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
          <mat-label>{{clientsPage?.SearchByNameOrEmail}}</mat-label>
          <input type="text" matInput (keyup)="onTypeEmail($event)">
        </mat-form-field>
      </div>
      <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 cpx-1">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
          <mat-label>{{usersPage?.SearchByTag}}</mat-label>
          <mat-select (valueChange)="onTagSearch($event)" msInfiniteScroll (infiniteScroll)="infiniteUserTagsForDropdown()">
            <mat-option value="">{{clientsPage?.None}}</mat-option>
            <mat-option *ngFor="let tag of allTags" [value]="tag.id">{{tag?.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center col-xl-1 col-lg-12 col-12" style="width: max-content;">
      <div class="copy-excel-print">
        <button class="thisone w-100" [ngbTooltip]="clientsPage?.excel" placement="top"
          tooltipClass="custom-tooltip" (click)="generateExcel()">
          <img src="../../../assets/excel-icon.svg">
        </button>
      </div>
    </div>
  </div>
  <div class="mb-30" style="overflow-x: auto;">
    <table class="customTable">
      <thead>
        <tr>
          <th>{{usersPage?.SNo}}</th>
          <th>{{usersPage?.Username}}</th>
          <th>{{usersPage?.EmailAddress}}</th>
          <th>{{usersPage?.UserTag}}</th>
          <!-- <th>{{clientsPage?.CreatedAt}}</th> -->
          <th class="cursor-pointer" (click)="getUsersList('sortByCreated')"> <!-- AlertStatus -->
            <div class="d-flex align-items-center gap-2">
              {{clientsPage?.CreatedAt}}
              <div class="d-flex flex-column justify-content-center">
                <i [ngClass]="ncmClientModelAPI.sortByCreated === 'asc' ? 'sort-active' : ''"
                  class="fas fa-sort-up sort-icon"></i>
                <i [ngClass]="ncmClientModelAPI.sortByCreated === 'desc' ? 'sort-active' : ''"
                  class="fas fa-sort-down sort-icon"></i>
              </div>
            </div>
          </th>
          <th>{{usersPage?.Role}}</th>
          <!-- <th>{{usersPage?.Status}}</th> -->
          <th class="cursor-pointer" (click)="getUsersList('sortByStatus')"> <!-- AlertStatus -->
            <div class="d-flex align-items-center gap-2">
              {{usersPage?.Status}}
              <div class="d-flex flex-column justify-content-center">
                <i [ngClass]="ncmClientModelAPI.sortByStatus === 'asc' ? 'sort-active' : ''"
                  class="fas fa-sort-up sort-icon"></i>
                <i [ngClass]="ncmClientModelAPI.sortByStatus === 'desc' ? 'sort-active' : ''"
                  class="fas fa-sort-down sort-icon"></i>
              </div>
            </div>
          </th>
          <th class="text-center">{{usersPage?.Actions}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users?.data; index as i">
          <td>{{((users?.paginationVariables?.current - 1) * pageSize) + i + 1}}</td>
          <td>{{user?.name}}</td>
          <td>{{user?.email}}</td>
          <td>{{user?.tag?.name}}</td>
          <td>{{user?.creationDate | date : 'MMM dd, YYYY'}}</td>
          <td>{{ ( language == 'en' ? user?.role.name_en : user?.role.name_ar ) || '-'}}</td>
          <td>{{ language == 'en' ? user?.status.name_en : user?.status.name_ar }}</td>
          <td>
            <div class="d-flex align-items-center gap-4 no-wrap">
              <div class="d-flex align-items-center no-wrap" (click)="setEditUser(user)" *ngxPermissionsOnly="['edit_ncm_user']">
                <button class="d-flex" [ngbTooltip]="usersPage?.EditUser" tooltipClass="custom-tooltip">
                  <img src="../../../../assets/alerteditor/edit-icon-small.png">
                </button>
              </div>
              <div class="d-flex align-items-center no-wrap" *ngxPermissionsOnly="['resend_invite']"
                (click)="showresend(user?.id)"
                [ngClass]="user.status.name_en.includes('Active') || user.status.name_en.includes('Suspended') ? 'disabled': ''">
                <button class="d-flex" [ngbTooltip]="usersPage?.ResendInvite" tooltipClass="custom-tooltip">
                  <img src="../../../../assets/inviteIcon.svg">
                </button>
              </div>
              <div class="d-flex align-items-center no-wrap" (click)="show(user?.id)" *ngxPermissionsOnly="['delete_user']">
                <button class="d-flex" [ngbTooltip]="usersPage?.Delete" tooltipClass="custom-tooltip">
                  <img src="../../../../assets/alerteditor/delete-icon.png">
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div style="padding: 1rem 0;" *ngIf="users?.length > 0" class="pagination dir-left row">
    <div *ngIf="users.length < 5" class="text col-lg-4 col-md-5 col-sm-12 col-12">{{usersPage?.Showing}}
      {{users.length}}
      {{usersPage?.Of}} {{users.length}} {{usersPage?.Entries}}</div>
    <div *ngIf="users.length > 5" class="text col-lg-4 col-md-5 col-sm-12 col-12">{{usersPage?.Showing}} {{pageSize}}
      {{usersPage?.Of}} {{users.length}} {{usersPage?.Entries}}</div>
    <ngb-pagination class="col-lg-4 col-md-5 col-sm-12 col-12" style="width: auto;padding: inherit;"
      [boundaryLinks]="false" [maxSize]="3" [(page)]="page" [pageSize]="pageSize"
      [collectionSize]="users.length"></ngb-pagination>
  </div> -->
  <div *ngIf="users?.paginationVariables?.pageCount > 1" class="col-12 d-flex justify-content-center pagination dir-left mb-30">
    <ngb-pagination [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [(page)]="page" (pageChange)="onPageChange($event)"
      [collectionSize]="users?.paginationVariables?.totalCount"></ngb-pagination>
  </div>
</div>

<div *ngIf="isResendInvite" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancelresend()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{usersPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{usersPage?.DoYouReallyWant}}
    </p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="resendInviteUser(resendId);cancelresend()">
        <i class="fas fa-trash-alt"></i>
        {{usersPage?.Yes}}
      </button>
      <button class="cancel-btn" (click)="cancelresend()">
        <i class="fas fa-times"></i>
        {{usersPage?.No}}
      </button>
    </div>
  </div>
</div>

<ng-template #createTag let-modal>
  <div class="modal-header">
    <div class="modal-title">
      <h5>{{usersPage?.CreateTags}}</h5>
    </div>
    <i (click)="createTagModal.dismiss('Cross click')" class="modal-close-btn far fa-times-circle"></i>
  </div>

  <div class="modal-body">
    <div class="d-flex flex-wrap col-12">
      <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameWidth" appearance="outline">
        <mat-label>{{usersPage?.EnterTagName}}</mat-label>
        <input [(ngModel)]="TagName" type="text" matInput>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <button (click)="createTagModal.close(TagName)" [ngClass]="!TagName ? 'disabled-btn' : ''"
        [disabled]="!TagName" class="widget-btn btn-primary-dark text-white">
          {{clientsPage?.Save}}
      </button>
    </div>
  </div>
</ng-template>
